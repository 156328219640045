import { Button } from "@coachhubio/nova-button";
import { Checkbox, CheckboxLabel } from "@coachhubio/nova-checkbox";
import { ArrowRightIcon } from "@coachhubio/nova-icon";
import { Label } from "@coachhubio/nova-label";
import { Heading, Text } from "@coachhubio/nova-text";
import { FormikErrors, useFormik } from "formik";

import { Container, Form, Input } from "./common";
import { useState } from "react";
import styled from "styled-components";
import { getTextStyle } from "@coachhubio/nova-typography";
import { TFunction } from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { PrivacyNoticeLink } from "../privacy-notice-link";

export type UserFormData = {
  businessEmail: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  phoneNumber: string;
  marketing: boolean;
  privacy: boolean;
};

type UserFormProps = UserFormData & {
  next: () => void;
} & (
    | {
        updateFields: (fields: Partial<UserFormData>) => void;
        readonly?: false;
      }
    | { updateFields?: never; readonly: true }
  );

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const PHONE_REGEX =
  /^(?:(?:\+|00)\d{1,3}) ?(?:(?:\()\d{3}\)|\d{3}) ?(?:\d{3}-\d{4}|\d{4,10})$/;
const UNICODE_REGEX = /[^\u0000-\u00ff]/;

const validate = (t: TFunction) => (values: UserFormData) => {
  const errors: FormikErrors<UserFormData> = {};

  if (!values.businessEmail) {
    errors.businessEmail = t("forms.user.fields.businessEmail.errors.required");
  } else if (!EMAIL_REGEX.test(values.businessEmail)) {
    errors.businessEmail = t("forms.user.fields.businessEmail.errors.format");
  }

  if (!values.firstName) {
    errors.firstName = t("forms.user.fields.firstName.errors.required");
  } else if (
    // Disable length test for Japanese/Non-latin languages
    !UNICODE_REGEX.test(values.firstName) &&
    values.firstName.length < 3
  ) {
    errors.firstName = t("forms.user.fields.firstName.errors.min", {
      chars: 3,
    });
  }

  if (!values.lastName) {
    errors.lastName = t("forms.user.fields.lastName.errors.required");
  } else if (
    // Disable length test for Japanese/Non-latin languages
    !UNICODE_REGEX.test(values.lastName) &&
    values.lastName.length < 3
  ) {
    errors.lastName = t("forms.user.fields.lastName.errors.min", { chars: 3 });
  }

  if (!values.jobTitle) {
    errors.jobTitle = t("forms.user.fields.jobTitle.errors.required");
  } else if (
    // Disable length test for Japanese/Non-latin languages
    !UNICODE_REGEX.test(values.jobTitle) &&
    values.jobTitle.length < 3
  ) {
    errors.jobTitle = t("forms.user.fields.jobTitle.errors.min", { chars: 3 });
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = t("forms.user.fields.phoneNumber.errors.required");
  } else if (!PHONE_REGEX.test(values.phoneNumber)) {
    errors.phoneNumber = t("forms.user.fields.phoneNumber.errors.format");
  }

  if (!values.privacy) {
    errors.privacy = t("forms.user.fields.privacy.errors.required");
  }
  return errors;
};

const StyledCheckbox = styled(Checkbox)`
  ${CheckboxLabel} {
    ${getTextStyle("text", "s", "bold")}
  }
`;

interface Flags {
  communication: boolean;
  privacy: boolean;
}

export default function UserForm({
  updateFields,
  next,
  readonly = false,
  ...data
}: UserFormProps) {
  const { t } = useTranslation();
  const {
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    touched,
    values,
  } = useFormik({
    initialValues: data,
    onSubmit: (values: UserFormData) => {
      updateFields?.(values);
      next();
    },
    validate: validate(t),
  });
  const [flags, setFlags] = useState<Flags>({
    communication: false,
    privacy: false,
  });

  function check(name: keyof Flags) {
    return function (checked: boolean) {
      setFlags((v) => ({ ...v, [name]: checked }));
    };
  }

  return (
    <Container backgroundColor="primaryDark85">
      <Heading size="l" color="brandDark">
        {t("forms.user.title")}
      </Heading>
      <Form onSubmit={handleSubmit}>
        <Input
          value={values.businessEmail}
          onChange={(_, event) => handleChange(event)}
          onBlur={handleBlur}
          name="businessEmail"
          placeholder={t("forms.user.fields.businessEmail.placeholder")}
          label={t("forms.user.fields.businessEmail.label")}
          type="email"
          required
          disabled={readonly}
          error={touched.businessEmail ? errors.businessEmail : undefined}
        />
        <Input
          value={values.firstName}
          onChange={(_, event) => handleChange(event)}
          onBlur={handleBlur}
          name="firstName"
          label={t("forms.user.fields.firstName.label")}
          type="text"
          placeholder={t("forms.user.fields.firstName.placeholder")}
          required
          disabled={readonly}
          error={touched.firstName ? errors.firstName : undefined}
        />
        <Input
          value={values.lastName}
          onChange={(_, event) => handleChange(event)}
          onBlur={handleBlur}
          name="lastName"
          label={t("forms.user.fields.lastName.label")}
          type="text"
          placeholder={t("forms.user.fields.lastName.placeholder")}
          required
          disabled={readonly}
          error={touched.lastName ? errors.lastName : undefined}
        />
        <Input
          value={values.jobTitle}
          onChange={(_, event) => handleChange(event)}
          onBlur={handleBlur}
          name="jobTitle"
          label={t("forms.user.fields.jobTitle.label")}
          type="text"
          placeholder={t("forms.user.fields.jobTitle.placeholder")}
          required
          disabled={readonly}
          error={touched.jobTitle ? errors.jobTitle : undefined}
        />
        <Input
          value={values.phoneNumber}
          onChange={(_, event) => handleChange(event)}
          onBlur={handleBlur}
          name="phoneNumber"
          label={
            <Label info={t("forms.user.fields.phoneNumber.tooltip")!}>
              {t("forms.user.fields.phoneNumber.label")}
            </Label>
          }
          type="tel"
          placeholder={t("forms.user.fields.phoneNumber.placeholder")}
          required
          disabled={readonly}
          error={touched.phoneNumber ? errors.phoneNumber : undefined}
        />
        <Text size="xs">{t("forms.user.fields.marketing.disclaimer")}</Text>
        <StyledCheckbox
          checked={values.marketing}
          name="marketing"
          onChange={(_, event) => handleChange(event)}
          color="primaryDark35"
        >
          {t("forms.user.fields.marketing.label")}
        </StyledCheckbox>
        <Text size="xs">{t("forms.user.fields.privacy.disclaimer")}</Text>
        <StyledCheckbox
          checked={values.privacy}
          name="privacy"
          onChange={(_, event) => handleChange(event)}
          color="primaryDark35"
        >
          {t("forms.user.fields.privacy.label")}*
        </StyledCheckbox>
        <Text size="xs">
          <Trans
            i18nKey="forms.user.unsubscribe"
            components={{
              url: <PrivacyNoticeLink />,
            }}
          />
        </Text>
        {readonly ? (
          <Button variant="secondary" type="submit">
            Clear fields
          </Button>
        ) : (
          <Button variant="cta" type="submit" disabled={!isValid}>
            {t("forms.user.actions.submit")} <ArrowRightIcon />
          </Button>
        )}
      </Form>
    </Container>
  );
}
