import { gap } from "@coachhubio/nova-spaces";
import styled from "styled-components";
import RoiCalculator from "./components/roi-calculator";
import RoiFeedback from "./components/roi-feedback";
import { RoiProvider } from "./context/roi-context";
import { useRef } from "react";
import useResizeObserver from "./hooks/useResizeObserver";
import { ScrollableIntoView } from "./components/roi-value";

export const Grid = styled.section`
  display: grid;
  align-items: start;
  grid-template-columns: 390px 1fr;
  ${gap("7xl")}

  @media (max-width: 680px) {
    display: flex;
    flex-direction: column;
  }
`;

export default function App() {
  const elRef = useRef<HTMLElement>(null);
  const scrollableView = useRef<ScrollableIntoView>(null);

  useResizeObserver(elRef, (el: DOMRectReadOnly) => {
    window.top?.postMessage(
      { type: "resize", width: el.width, height: el.height },
      "*"
    );
  });

  function onComputed() {
    scrollableView.current?.scrollIntoView();
  }

  return (
    <section className="container" ref={elRef}>
      <RoiProvider>
        <Grid>
          <RoiCalculator onComputed={onComputed} />
          <RoiFeedback ref={scrollableView} />
        </Grid>
      </RoiProvider>
    </section>
  );
}
