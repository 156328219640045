import { motion, useAnimate, usePresence } from "framer-motion";
import { ReactNode, useEffect } from "react";
import styled from "styled-components";

interface FlipCardProps {
  children: ReactNode;
}

const Container = styled(motion.div)`
  position: relative;
  z-index: 0;
  grid-column: 1/2;
  grid-row: 1/2;
  backface-visibility: hidden;
`;

export default function FlipCard({ children }: FlipCardProps) {
  const [isPresent, safeToRemove] = usePresence();
  const [scope, animate] = useAnimate();

  useEffect(() => {
    if (isPresent) {
      // Component has been mounted
      const enterAnimation = async () => {
        await animate(scope.current, { rotateY: 0 }, { ease: "easeInOut" });
      };
      enterAnimation();
    } else {
      // Component is going to be unmounted
      const exitAnimation = async () => {
        await animate(scope.current, { rotateY: -180 }, { ease: "easeInOut" });
        safeToRemove();
      };
      exitAnimation();
    }
  }, [isPresent]);

  return (
    <Container ref={scope} initial={{ rotateY: 180 }}>
      {children}
    </Container>
  );
}
