import { borderRadius } from "@coachhubio/nova-borders";
import {
  getBackgroundColor,
  getBorderColor,
  getColor,
} from "@coachhubio/nova-colors";
import {
  paddingBottom,
  paddingEnd,
  paddingStart,
  paddingTop,
} from "@coachhubio/nova-spaces";
import { Text } from "@coachhubio/nova-text";
import { getTextStyle } from "@coachhubio/nova-typography";
import { forwardRef, type Ref, useRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import styled, { StyledComponent } from "styled-components";

const Container = styled.div`
  width: max-content;
  ${getBackgroundColor("neutral")}
  ${paddingTop("xl")}
  ${paddingBottom("xl")}
  ${paddingStart("2xl")}
  ${paddingEnd("2xl")}
  ${borderRadius("m")}
  ${getColor("brandDark")}
  box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);
`;

interface ValueProps {
  blurred?: boolean;
}
const Value = styled.div<ValueProps>`
  ${getTextStyle("heading", "2xl", "bold")}
  ${({ blurred = false }) => (blurred ? "filter: blur(15px);" : "")}
  text-align: center;
`;
const Hr = styled.hr`
  border-width: 3px;
  border-style: solid;
  border-radius: 30px;
  ${getBorderColor("secondaryDark65")}
`;

interface RoiValueProps {
  value: number;
  blurred?: boolean;
}

export interface ScrollableIntoView {
  scrollIntoView: () => void;
}

const RoiValue = forwardRef(
  ({ value, blurred }: RoiValueProps, ref: Ref<ScrollableIntoView>) => {
    const { t } = useTranslation();
    const containerRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(ref, () => ({
      scrollIntoView() {
        containerRef.current?.scrollIntoView({
          block: "center",
          inline: "center",
        });
      },
    }));

    return (
      <Container ref={containerRef}>
        <Value blurred={blurred}>{(value * 100).toFixed(0)}%</Value>
        <Hr />
        <Text size="l">{t("feedback.roi")}</Text>
      </Container>
    );
  }
);

export default RoiValue;
