import { Button } from "@coachhubio/nova-button";
import { Label } from "@coachhubio/nova-label";
import { Heading } from "@coachhubio/nova-text";
import { NumericFormat } from "react-number-format";
import { useTranslation } from "react-i18next";

import { Container, Form, Input } from "./common";
import { FormikErrors, useFormik } from "formik";
import { Ref, forwardRef, useImperativeHandle } from "react";

import {
  stringToNumber,
  currencyToNumber,
  percentageToNumber,
} from "../utils/parser";
import { InputProps } from "@coachhubio/nova-input";
import { TFunction } from "i18next";

export type RoiFormData = {
  numberOfParticipants: string;
  averageSalary: string;
  averageTurnoverRate: string;
  companyRevenue: string;
  numberOfEmployees: string;
};

const MIN_AVERAGE_SALARY = 10_000;
const MIN_COMPANY_REVENUE = 10_000;

type RoiFormProps = RoiFormData & {
  next?: () => void;
} & (
    | {
        readonly?: false;
        updateFields: (fields: Partial<RoiFormData>) => void;
      }
    | {
        readonly: true;
        updateFields?: never;
      }
  );

const validate =
  (t: TFunction) =>
  (values: RoiFormData): FormikErrors<RoiFormData> => {
    const errors: FormikErrors<RoiFormData> = {};

    if (!values.averageSalary) {
      errors.averageSalary = t(
        "forms.roi.fields.averageSalary.errors.required"
      )!;
    } else if (currencyToNumber(values.averageSalary) < MIN_AVERAGE_SALARY) {
      errors.averageSalary = t("forms.roi.fields.averageSalary.errors.min", {
        amount: MIN_AVERAGE_SALARY,
      })!;
    }

    if (!values.averageTurnoverRate) {
      errors.averageTurnoverRate = t(
        "forms.roi.fields.averageTurnoverRate.errors.required"
      )!;
    } else if (percentageToNumber(values.averageTurnoverRate) <= 0) {
      errors.averageTurnoverRate = t(
        "forms.roi.fields.averageTurnoverRate.errors.min"
      )!;
    }

    if (!values.companyRevenue) {
      errors.companyRevenue = t(
        "forms.roi.fields.companyRevenue.errors.required"
      )!;
    } else if (currencyToNumber(values.companyRevenue) < MIN_COMPANY_REVENUE) {
      errors.companyRevenue = t("forms.roi.fields.companyRevenue.errors.min", {
        amount: MIN_COMPANY_REVENUE,
      })!;
    }

    if (!values.numberOfEmployees) {
      errors.numberOfEmployees = t(
        "forms.roi.fields.numberOfEmployees.errors.required"
      )!;
    } else if (stringToNumber(values.numberOfEmployees) <= 0) {
      errors.numberOfEmployees = t(
        "forms.roi.fields.numberOfEmployees.errors.min"
      )!;
    } else if (
      stringToNumber(values.numberOfEmployees) <
      stringToNumber(values.numberOfParticipants)
    ) {
      errors.numberOfEmployees = t(
        "forms.roi.fields.numberOfEmployees.errors.greater"
      )!;
    }

    if (!values.numberOfParticipants) {
      errors.numberOfParticipants = t(
        "forms.roi.fields.numberOfParticipants.errors.required"
      )!;
    } else if (stringToNumber(values.numberOfParticipants) <= 0) {
      errors.numberOfParticipants = t(
        "forms.roi.fields.numberOfParticipants.errors.min"
      )!;
    }

    return errors;
  };

// Interface used to imperativly reset ROI Form Data
export interface ResettableForm {
  resetForm: (values: RoiFormData) => void;
}

type CustomInputProps = Omit<InputProps, "onChange"> & {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const CustomInput = ({ onChange, ...props }: CustomInputProps) => {
  return (
    <Input
      {...props}
      onChange={(_, event) => {
        onChange(event);
      }}
    />
  );
};

const RoiForm = forwardRef(
  (
    { updateFields, next, readonly = false, ...data }: RoiFormProps,
    ref: Ref<ResettableForm>
  ) => {
    const { t } = useTranslation();

    // I need to use `useFormik` hook to retrieve the `resetForm` method
    // In alternative, I can use Formik context with an hidden button with `handleReset`
    // and call it through a `useImperativeHandle` and a reference
    const {
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      isValid,
      touched,
      resetForm,
      values,
    } = useFormik({
      initialValues: data,
      onSubmit: (fields: RoiFormData) => {
        updateFields?.(fields);
        next?.();
      },
      validate: validate(t),
    });

    // Using `useImperativeHandle` I can allow parent component to call
    // `resetForm` method
    useImperativeHandle(ref, () => ({
      resetForm(values: RoiFormData) {
        resetForm({ values });
      },
    }));

    return (
      <Container backgroundColor="primaryDark95">
        <Heading size="m" color="brandDark">
          {t("forms.roi.title")}
        </Heading>
        <Form onSubmit={handleSubmit}>
          <NumericFormat
            value={values.numberOfParticipants}
            name="numberOfParticipants"
            onChange={handleChange}
            onBlur={handleBlur}
            customInput={CustomInput}
            thousandSeparator=","
            allowNegative={false}
            decimalScale={0}
            label={
              <Label info={t("forms.roi.fields.numberOfParticipants.tooltip")!}>
                {t("forms.roi.fields.numberOfParticipants.label")}
              </Label>
            }
            placeholder={t(
              "forms.roi.fields.numberOfParticipants.placeholder",
              { value: 50 }
            )}
            required
            disabled={readonly}
            error={
              touched.numberOfParticipants
                ? errors.numberOfParticipants
                : undefined
            }
          />
          <NumericFormat
            value={values.averageSalary}
            name="averageSalary"
            allowLeadingZeros
            prefix={"$"}
            thousandSeparator=","
            customInput={CustomInput}
            onChange={handleChange}
            onBlur={handleBlur}
            label={
              <Label info={t("forms.roi.fields.averageSalary.tooltip")!}>
                {t("forms.roi.fields.averageSalary.label")}
              </Label>
            }
            placeholder={t("forms.roi.fields.averageSalary.placeholder", {
              value: 65_000,
            })}
            required
            disabled={readonly}
            error={touched.averageSalary ? errors.averageSalary : undefined}
          />
          <NumericFormat
            value={values.averageTurnoverRate}
            name="averageTurnoverRate"
            suffix={"%"}
            onChange={handleChange}
            onBlur={handleBlur}
            customInput={CustomInput}
            label={
              <Label info={t("forms.roi.fields.averageTurnoverRate.tooltip")!}>
                {t("forms.roi.fields.averageTurnoverRate.label")}
              </Label>
            }
            placeholder="20%"
            required
            disabled={readonly}
            error={
              touched.averageTurnoverRate
                ? errors.averageTurnoverRate
                : undefined
            }
          />
          <NumericFormat
            value={values.companyRevenue}
            name="companyRevenue"
            prefix={"$"}
            thousandSeparator=","
            customInput={CustomInput}
            onChange={handleChange}
            onBlur={handleBlur}
            label={
              <Label info={t("forms.roi.fields.companyRevenue.tooltip")!}>
                {t("forms.roi.fields.companyRevenue.label")}
              </Label>
            }
            placeholder={t("forms.roi.fields.companyRevenue.placeholder", {
              value: 100_000_000,
            })}
            required
            disabled={readonly}
            error={touched.companyRevenue ? errors.companyRevenue : undefined}
          />
          <NumericFormat
            value={values.numberOfEmployees}
            name="numberOfEmployees"
            onChange={handleChange}
            onBlur={handleBlur}
            customInput={CustomInput}
            allowNegative={false}
            decimalScale={0}
            label={
              <Label info={t("forms.roi.fields.numberOfEmployees.tooltip")!}>
                {t("forms.roi.fields.numberOfEmployees.label")}
              </Label>
            }
            placeholder={t("forms.roi.fields.numberOfEmployees.placeholder", {
              value: 250,
            })}
            required
            disabled={readonly}
            error={
              touched.numberOfEmployees ? errors.numberOfEmployees : undefined
            }
          />
          {readonly ? (
            <>
              <Button variant="secondary" type="submit">
                {t("forms.roi.actions.restart")}
              </Button>
              <Button
                variant="cta"
                onClick={() => {
                  window.top?.postMessage(
                    {
                      type: "navigate",
                      href: "/request-demo",
                    },
                    "*"
                  );
                }}
              >
                {t("forms.roi.actions.demo")}
              </Button>
            </>
          ) : (
            <Button variant="cta" type="submit" disabled={!isValid}>
              {t("forms.roi.actions.submit")}
            </Button>
          )}
        </Form>
      </Container>
    );
  }
);

export default RoiForm;
