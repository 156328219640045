import { Card } from "@coachhubio/nova-card";
import { getColor, getBorderColor } from "@coachhubio/nova-colors";
import { marginBottom, marginTop } from "@coachhubio/nova-spaces";
import { getTextStyle } from "@coachhubio/nova-typography";
import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";
import RoiValue, { ScrollableIntoView } from "./roi-value";
import { useRoi } from "../context/roi-context";
import RoiCalculation from "./roi-calculation";
import { Ref, forwardRef, useRef } from "react";
import { useTranslation, Trans } from "react-i18next";

// The 50px on top and margin-top is:
// 26px margin of RoiCalculator > Container +
// 24px padding on Common > Container
const Container = styled.div`
  ${getTextStyle("text", "base", "regular")}
  position: sticky;
  top: 50px;
  margin-top: 50px;
`;

const Subtitle = styled.h5`
  ${getColor("primaryDark35")}
  ${getTextStyle("heading", "m", "regular")}
  ${marginTop("none")}
  ${marginBottom("xs")}
`;
const Title = styled.h3`
  ${getColor("primaryDark35")}
  ${getTextStyle("heading", "xl", "bold")}
  font-size: 30px;
  ${marginTop("none")}
  ${marginBottom("xs")}
`;

const Paragraph = styled.p`
  ${marginBottom("base")}
  ${getColor("text40")}
`;

const Hr = styled(motion.hr)`
  ${marginTop("2xl")}
  ${marginBottom("2xl")}
  border-width: 1px;
  border-style: solid;
  ${getBorderColor("neutral85")}
`;

const RoiFeedback = forwardRef(({}, ref: Ref<ScrollableIntoView>) => {
  const { t } = useTranslation();
  const { submitted, touched, value, error } = useRoi();
  return (
    <Container>
      <Subtitle>{t("feedback.subtitle")}</Subtitle>
      <Title>{t("feedback.title")}</Title>
      <Paragraph>
        <Trans i18nKey="feedback.text" />
      </Paragraph>
      <RoiValue ref={ref} value={value} blurred={!submitted} />
      {error ? <Card borderColor="error40">{error}</Card> : null}
      <AnimatePresence>
        {touched ? (
          <>
            <Hr
              key="hr"
              initial={{ opacity: 0, scaleX: 0 }}
              animate={{ opacity: 1, scaleX: 1 }}
            />
            <RoiCalculation
              key="roi-calculation"
              initial={{ opacity: 0, scaleY: 0 }}
              animate={{ opacity: 1, scaleY: 1 }}
            />
          </>
        ) : null}
      </AnimatePresence>
    </Container>
  );
});

export default RoiFeedback;
