import { RefObject, useCallback, useLayoutEffect, useState } from "react";

interface Size {
  width: number;
  height: number;
}

export default function useResizeObserver(
  ref: RefObject<HTMLElement>,
  callback?: (entry: DOMRectReadOnly) => void
) {
  const [size, setSize] = useState<Size | undefined>();
  const handleResize = useCallback(
    (entries: ResizeObserverEntry[]) => {
      if (!Array.isArray(entries)) {
        return;
      }

      const entry = entries[0];
      setSize({
        width: entry.contentRect.width,
        height: entry.contentRect.height,
      });

      if (callback) {
        callback(entry.contentRect);
      }
    },
    [callback]
  );

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    let ro: ResizeObserver | null = new ResizeObserver(handleResize);
    ro.observe(ref.current);

    return () => {
      ro!.disconnect();
      ro = null;
    };
  }, [ref]);

  return size;
}
