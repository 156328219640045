import { useEffect, useRef, useState } from "react";
import { borderRadius, borderWidth } from "@coachhubio/nova-borders";
import styled from "styled-components";
import { FormState, useMultistepForm } from "../hooks/useMultistepForm";
import RoiForm, { ResettableForm, RoiFormData } from "./forms/roi-form";
import UserForm, { UserFormData } from "./forms/user-form";
import { useRoi } from "../context/roi-context";
import { AnimatePresence } from "framer-motion";
import FlipCard from "./flip-card";
import { RoiCalculatorData } from "../services/roi-service";
import {
  stringToNumber,
  currencyToNumber,
  percentageToNumber,
} from "./utils/parser";

type RoiCalculatorFormData = {
  roi: RoiFormData;
  user: UserFormData;
};
const INITIAL_STATE: RoiCalculatorFormData = {
  roi: {
    averageSalary: "",
    averageTurnoverRate: "20",
    companyRevenue: "",
    numberOfEmployees: "",
    numberOfParticipants: "",
  },
  user: {
    businessEmail: "",
    firstName: "",
    jobTitle: "",
    lastName: "",
    phoneNumber: "",
    marketing: false,
    privacy: false,
  },
};

const Container = styled.div`
  width: 100%;
  display: grid;
  ${borderRadius("l")}
  ${borderWidth("none")}
  box-shadow: 26px -26px #8d8ed8;
  margin-top: 26px;
  margin-right: 26px;
`;

const requieredField: Array<keyof RoiCalculatorFormData["user"]> = [
  "businessEmail",
  "firstName",
  "lastName",
  "jobTitle",
  "phoneNumber",
];

interface RoiCalculatorProps {
  onComputed: () => void;
}

const MESSAGE_TYPE = "identify-user";

function getHubspotCookie(): Promise<string | undefined> {
  console.log("getHubspotCookie...");
  return new Promise((resolve) => {
    // used to check if promise was resolved
    let resolved = false;
    function handleMessage(
      event: MessageEvent<{ type: string; hutk?: string }>
    ) {
      console.log(`on message: ${JSON.stringify(event.data)}`);
      if (event.data.type === MESSAGE_TYPE) {
        resolved = true;
        window.removeEventListener("message", handleMessage);
        console.log(`resolve: ${event.data.hutk}`);
        resolve(event.data.hutk);
      }
    }
    setTimeout(function () {
      console.log("timeout!", resolved);
      if (!resolved) {
        resolved = true;
        window.removeEventListener("message", handleMessage);
        console.log("resolve: undefined");
        resolve(undefined);
      }
    }, 500);
    window.addEventListener("message", handleMessage);
    window.top?.postMessage({ type: MESSAGE_TYPE }, "*");
  });
}

export default function RoiCalculator({ onComputed }: RoiCalculatorProps) {
  const [formData, setFormData] = useState(INITIAL_STATE);
  const { compute, reset } = useRoi();
  const roiFormRef = useRef<ResettableForm>(null);

  const updateFields =
    <K extends keyof RoiCalculatorFormData>(key: K) =>
    (data: Partial<RoiCalculatorFormData[typeof key]>) => {
      setFormData((value) => ({ ...value, [key]: { ...value[key], ...data } }));
    };
  function moveNext() {
    next();
  }
  const { start, state, content, next } = useMultistepForm(
    [
      {
        content: (
          <FlipCard key="roi-form">
            <RoiForm
              ref={roiFormRef}
              {...formData.roi}
              updateFields={updateFields("roi")}
              next={moveNext}
            />
          </FlipCard>
        ),
      },
      {
        content: (
          <FlipCard key="user-form">
            <UserForm
              {...formData.user}
              updateFields={updateFields("user")}
              next={moveNext}
            />
          </FlipCard>
        ),
        shouldBeSkipped: (): boolean => {
          const check = Object.entries(formData.user)
            .filter(([key, value]) =>
              requieredField.includes(
                key as keyof RoiCalculatorFormData["user"]
              )
            )
            .map(([key, value]) => value)
            .every((value) => !!value);
          return check;
        },
      },
    ],
    {
      // On start reset roi values
      onStarted: () => {
        // reset ROI feedback
        reset();
      },
      onCompleted: async () => {
        const data: RoiCalculatorData = {
          roi: {
            numberOfParticipants: stringToNumber(
              formData.roi.numberOfParticipants
            ),
            averageSalary: currencyToNumber(formData.roi.averageSalary),
            averageTurnoverRate: percentageToNumber(
              formData.roi.averageTurnoverRate
            ),
            companyRevenue: currencyToNumber(formData.roi.companyRevenue),
            numberOfEmployees: stringToNumber(formData.roi.numberOfEmployees),
          },
          user: formData.user,
        };
        const htuk = await getHubspotCookie();
        console.log("htuk:", htuk);
        // compute ROI value
        await compute(data, htuk);
        onComputed();
      },
    }
  );

  // Start multistep form on load
  useEffect(() => {
    start();
  }, []);

  if (state === FormState.Pending) {
    return null;
  }

  return (
    <Container>
      <AnimatePresence initial={false}>
        {state === FormState.Running ? (
          content
        ) : (
          <FlipCard key="roi-form">
            <RoiForm {...formData.roi} next={start} readonly />
          </FlipCard>
        )}
      </AnimatePresence>
    </Container>
  );
}
