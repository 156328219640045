import client from "./client";

export type RoiCalculatorData = {
  roi: RoiData;
  user: UserData;
};

export type RoiData = {
  numberOfParticipants: number;
  averageSalary: number;
  averageTurnoverRate: number;
  companyRevenue: number;
  numberOfEmployees: number;
};

export type UserData = {
  businessEmail: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  phoneNumber: string;
};

interface ComputerRoiResult {
  result: number;
}
export async function computeRoi(
  data: RoiCalculatorData,
  htuk: string | undefined
): Promise<number> {
  try {
    console.log(`Compute ROI: ${JSON.stringify({ ...data, htuk }, null, 2)}`);
    const response = await client.post<ComputerRoiResult>("/compute", {
      ...data,
      htuk,
    });
    return response.data.result;
  } catch (err) {
    console.log("Failed to compute ROI:", err);
    throw err;
  }
}
