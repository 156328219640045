import { type ReactElement, useReducer, useEffect } from "react";

export type Step = {
  content: ReactElement;
  shouldBeSkipped?: () => boolean;
};

export enum FormState {
  Pending,
  Running,
  Completed,
}

enum MultistepFormActionKind {
  Start,
  Next,
  Prev,
}

type MultistepFormAction =
  | { type: MultistepFormActionKind.Start }
  | { type: MultistepFormActionKind.Next }
  | { type: MultistepFormActionKind.Prev };

type MultistepFormState =
  | { state: FormState.Pending }
  | { state: FormState.Running; currentStep: number }
  | { state: FormState.Completed };

const INITIAL_STATE: MultistepFormState = { state: FormState.Pending };

function getInitialStep(steps: Step[]) {
  if (steps[0].shouldBeSkipped?.()) {
    return getNextStep(steps, 1);
  }
  return 0;
}

function getNextStep(steps: Step[], currentStep: number): number {
  // check if current step is the last one
  if (currentStep >= steps.length - 1) {
    return -1;
  }
  // check if we should skip next step
  if (steps[currentStep + 1].shouldBeSkipped?.()) {
    return getNextStep(steps, currentStep + 1);
  }
  return currentStep + 1;
}

function checkIfCompleted(currentStep: number): MultistepFormState {
  // check if there is a step to show
  if (currentStep >= 0) {
    return { state: FormState.Running, currentStep };
  }
  // move to completed state
  return { state: FormState.Completed };
}

function multistepFormReducer(steps: Step[]) {
  return function (
    state: MultistepFormState,
    action: MultistepFormAction
  ): MultistepFormState {
    switch (state.state) {
      // Handle transitions from Pending state
      case FormState.Pending:
        if (action.type === MultistepFormActionKind.Start) {
          const initialStep = getInitialStep(steps);
          return checkIfCompleted(initialStep);
        }
        break;

      // Handle transitions from Running state
      case FormState.Running:
        if (action.type === MultistepFormActionKind.Next) {
          const nextStep = getNextStep(steps, state.currentStep);
          return checkIfCompleted(nextStep);
        }
        if (action.type === MultistepFormActionKind.Prev) {
          // Get previous step
          const nextStep = Math.max(0, state.currentStep - 1);
          return { state: FormState.Running, currentStep: nextStep };
        }
      // TODO: should I allow user to restart a running multistep form?

      // Handle transitions from Completed state
      case FormState.Completed:
        if (action.type === MultistepFormActionKind.Start) {
          // Get initial step and restart form
          const initialStep = getInitialStep(steps);
          return checkIfCompleted(initialStep);
        }
    }

    return state;
  };
}

type MultistepFormOpts = {
  onStarted?: () => void;
  onCompleted?: () => void;
};
export function useMultistepForm(
  steps: Step[],
  { onStarted, onCompleted }: MultistepFormOpts = {}
) {
  const [state, dispatch] = useReducer(
    multistepFormReducer(steps),
    INITIAL_STATE
  );

  // Fire events on state change
  useEffect(() => {
    switch (state.state) {
      case FormState.Running:
        onStarted?.();
        break;
      case FormState.Completed:
        onCompleted?.();
        break;
    }
  }, [state.state]);

  function next() {
    dispatch({ type: MultistepFormActionKind.Next });
  }

  function back() {
    dispatch({ type: MultistepFormActionKind.Prev });
  }

  function start() {
    dispatch({ type: MultistepFormActionKind.Start });
  }
  return {
    state: state.state,
    ...(state.state === FormState.Running
      ? {
          currentStep: state.currentStep,
          content: steps[state.currentStep]?.content,
        }
      : undefined),
    next,
    back,
    start,
  };
}
