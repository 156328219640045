import i18n, { InitOptions } from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import I18NextHttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const options: InitOptions = {
  debug: import.meta.env.MODE !== "production",
  fallbackLng: "en-US",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  detection: {
    order: ["querystring"],
  },
};

i18n
  // loads translations from the server
  .use(I18NextHttpBackend)
  // detect user language
  .use(I18nextBrowserLanguageDetector)
  // pass the i18n instance to react-i18next
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(options);

i18n.services.formatter?.add("CURRENCY", (value, lng, options) => {
  return new Intl.NumberFormat(lng, {
    style: "currency",
    currency: "USD",
    currencyDisplay: "symbol",
    maximumFractionDigits: 0,
  }).format(Number(value));
});

i18n.services.formatter?.add("NUMBER", (value, lng, options) => {
  return new Intl.NumberFormat(lng, {
    style: "decimal",
    maximumFractionDigits: 0,
  }).format(Number(value));
});

export default i18n;
