import { borderRadius, borderWidth } from "@coachhubio/nova-borders";
import {
  ColorAlias,
  getBackgroundColor,
  getColor,
} from "@coachhubio/nova-colors";
import { Input as NovaInput, InputWrapper } from "@coachhubio/nova-input";
import { gap, padding } from "@coachhubio/nova-spaces";
import styled from "styled-components";

interface ContainerProps {
  backgroundColor: ColorAlias;
}
export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  ${({ backgroundColor }) => getBackgroundColor(backgroundColor)}
  ${borderRadius("l")}
  ${borderWidth("none")}
  ${gap("base")}
  box-sizing: border-box;
  ${padding("l")}
  & label svg {
    ${getColor("secondaryDark65")}
  }
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  ${gap("base")}

  & > * {
    width: 100%;
  }
`;

export const Input = styled(NovaInput)`
  ${InputWrapper} {
    ${getBackgroundColor("neutral")}
  }
`;
