export function stringToNumber(string: string): number {
  return Number(string.replaceAll(",", ""));
}

export function currencyToNumber(string: string): number {
  return stringToNumber(string.replace("$", ""));
}

export function percentageToNumber(string: string): number {
  return stringToNumber(string.replace("%", "")) / 100;
}
