import { borderRadius } from "@coachhubio/nova-borders";
import { getBorderColor, getColor } from "@coachhubio/nova-colors";
import {
  gap,
  paddingBottom,
  paddingEnd,
  paddingStart,
  paddingTop,
} from "@coachhubio/nova-spaces";
import { Heading } from "@coachhubio/nova-text";
import { getTextStyle } from "@coachhubio/nova-typography";
import { memo } from "react";
import styled from "styled-components";
import Chemistry from "./icons/chemistry.svg";
import { HTMLMotionProps, motion } from "framer-motion";
import { useTranslation, Trans } from "react-i18next";

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  ${gap("l")}
  ${getColor("primaryDark35")}
  ${getTextStyle("text", "s", "regular")}
  & ul {
    margin: 0;
  }
`;

const FormulaWrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f7f7f9;
  ${borderRadius("m")}
  ${gap("xs")}
  ${paddingTop("xs")}
  ${paddingBottom("xs")}
  ${paddingStart("m")}
  ${paddingEnd("m")}
  ${getTextStyle("text", "s", "bold")}
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${gap("xs")}
  & hr {
    border-width: 1px;
    border-style: solid;
    ${getBorderColor("primaryDark35")}
    align-self: stretch;
    margin: 0;
  }
`;
const Span = styled.span`
  text-align: center;
`;

type Props = Pick<HTMLMotionProps<"div">, "animate" | "initial" | "exit">;

function RoiCalculation(props: Props) {
  const { t } = useTranslation();

  return (
    <Wrapper {...props}>
      <Heading>{t("feedback.formula.title")}</Heading>
      <FormulaWrapper>
        <img src={Chemistry} alt="Chemestry" />
        <Span>{t("feedback.formula.value")}</Span>
        <Span>=</Span>
        <Column>
          <Span>{t("feedback.formula.numerator")}</Span>
          <hr />
          <Span>{t("feedback.formula.denominator")}</Span>
        </Column>
      </FormulaWrapper>
      <Trans
        i18nKey="feedback.benefits"
        components={{
          div: <div />,
          strong: <strong />,
          ul: <ul />,
          li: <li />,
        }}
      />
    </Wrapper>
  );
}

export default memo(RoiCalculation);
