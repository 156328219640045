import {
  type ReactNode,
  createContext,
  useState,
  useMemo,
  useContext,
} from "react";
import { isAxiosError } from "axios";
import { RoiCalculatorData, computeRoi } from "../services/roi-service";

const RoiContext = createContext<
  | {
      value: number;
      submitting: boolean;
      submitted: boolean;
      touched: boolean;
      error?: string;
      compute: (
        data: RoiCalculatorData,
        htuk: string | undefined
      ) => Promise<void>;
      reset: () => void;
    }
  | undefined
>(undefined);

interface RoiProviderProps {
  children: ReactNode;
}
export function RoiProvider({ children }: RoiProviderProps) {
  const [value, setValue] = useState<number>(NaN);
  const [touched, setTouched] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const compute = useMemo(
    () => async (data: RoiCalculatorData, htuk: string | undefined) => {
      setTouched(true);
      setSubmitting(true);
      setError(undefined);
      try {
        const value = await computeRoi(data, htuk);
        // Store value
        setValue(value);
        // Reveal ROI
        setSubmitted(true);
      } catch (err) {
        if (isAxiosError(err)) {
          if (err.code === "400") {
            setError("Invalid Request");
          } else {
            setError(err.message);
          }
        } else {
          setError(`${err}`);
        }
      }
      setSubmitting(false);
    },
    []
  );

  const reset = useMemo(
    () => () => {
      // Hide ROI
      setSubmitted(false);
    },
    []
  );

  return (
    <RoiContext.Provider
      value={{ value, compute, submitting, submitted, reset, touched, error }}
    >
      {children}
    </RoiContext.Provider>
  );
}

export function useRoi() {
  const context = useContext(RoiContext);
  if (!context) {
    throw new Error("useRoi must be used within a RoiProvider");
  }
  return context;
}
