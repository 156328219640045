import { Link } from "@coachhubio/nova-link";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

const links = {
  en: "https://www.coachhub.com/privacy-notice/",
  fr: "https://www.coachhub.com/fr/protection-des-donnees/",
  de: "https://www.coachhub.com/de/privacy-notice/",
  es: "https://www.coachhub.com/es/privacy-notice/",
} as const;
type Languages = keyof typeof links;

export const PrivacyNoticeLink: React.FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const { i18n } = useTranslation();
  const lang = (i18n.resolvedLanguage ?? "en").split("-")[0] as Languages;

  return (
    <Link
      href={links[lang] ?? links["en"]}
      rel="noopener noreferrer"
      size="xs"
      target="_blank"
    >
      {children}
    </Link>
  );
};
